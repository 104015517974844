const GAME_CODE_LENGTH = 5;
const alphanumericRegex = /^[a-z0-9]+$/i;

/**
 * Generate a (hopefully) unique alphanumeric string using uppercase letters and numbers.
 *
 * Taken from https://stackoverflow.com/a/1349426.
 */
function generateId(length = GAME_CODE_LENGTH) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;

    let result = '';

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function validateGameCode(code: string) {
    return code.length === GAME_CODE_LENGTH && alphanumericRegex.test(code);
}

export default generateId;
