import Game, { UpdateRoundData } from '../Game';

// Used by cloud function
export default async function endRound(game: Game, runId: string) {
    const gameState = await game.getGame();

    if (!gameState) {
        throw new Error(`No game found for ID '${game.gameCode}'.`);
    }

    // If there's no runId or it doesn't match the one for this invocation then don't do anything
    // because this endRound call is no longer valid.
    if (!gameState.runId || gameState.runId !== runId) return;

    // If the round has been paused, don't need to write any new values
    if (gameState.roundPausedTimeRemaining) return;

    // Ensure one of the teams is active and then switch to the other
    if (!gameState.activeTeam) {
        throw new Error(`Game has no active team.`);
    }
    const newActiveTeam = game.teams.getOtherTeam(gameState.activeTeam);
    await game.teams.iterateTeamClueGiver(newActiveTeam);

    const roundData: UpdateRoundData = {
        runId: null, // set back to null because run is over
        betweenRounds: false,
        roundActive: false,
        roundStartTimestamp: null,
        roundWillEndTimestamp: null,
        roundPausedTimeRemaining: null,
        roundWords: null,
        roundWordsIndex: null,
    };

    await game.updateGame({
        ...roundData,
        activeTeam: newActiveTeam,
    });
}
