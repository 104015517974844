import { getDbVal } from '.';
import Game from './Game';
import useMutation from './useMutation';

interface WordObj {
    word: string;
    round: number;
}

export interface WordObjWithKey extends WordObj {
    key: string;
}

interface IWords {
    // Number is round it has been guessed on. So a 2 means it's been completed for round 2
    [key: string]: WordObj;
}

interface AddWordsArgs {
    words: string[];
    userId: string;
}

export default class Words {
    static ParentPath = 'words';
    // /words/[gameCode]
    public ref: firebase.database.Reference;

    constructor(private game: Game) {
        this.ref = this.game.db.ref(Words.ParentPath).child(this.game.gameCode);
    }

    async getWords() {
        const wordsMap = await getDbVal<IWords>(this.ref);
        if (!wordsMap) return [];

        // Convert to array and randomize
        const words: string[] = [];
        for (const { word } of Object.values(wordsMap)) {
            words.push(word);
        }

        return words;
    }

    setWordRoundValue(wordId: string, value: number) {
        const roundKey: keyof WordObj = 'round';
        return this.ref.child(wordId).child(roundKey).set(value);
    }

    async addWordsToGame({ words, userId }: AddWordsArgs) {
        // Ensure the game hasn't already started
        const hasStarted = await this.game.getGameValue('hasStarted');
        if (hasStarted) {
            throw new Error(
                `Game '${this.game.gameCode}' has already started, cannot add new words.`
            );
        }

        const cleanedWords = words.filter((word) => word !== '').map((word) => word.trim());

        await Promise.all(
            cleanedWords.map((word) => {
                const value: WordObj = { word, round: 0 };
                return this.ref.push().set(value);
            })
        );

        await this.game.users.setUserValue(userId, 'hasEnteredWords', true);
    }

    async getWordObjsForRound(round: number) {
        // The word's number value in the database is the round it has *already been guessed for*
        // so for available words for Round 1, we'd query for any words with 0 as their value.
        const childKey: keyof WordObj = 'round';
        const wordsMap = await getDbVal<IWords>(this.ref.orderByChild(childKey).equalTo(round - 1));
        if (!wordsMap) return [];

        // Convert to array and randomize
        const words: WordObjWithKey[] = [];
        for (const key of Object.keys(wordsMap)) {
            const val = wordsMap[key];
            words.push({
                key,
                word: val.word,
                round: val.round,
            });
        }
        shuffleArray(words);

        return words;
    }
}

export function useAddWordsToGame(game: Game) {
    return useMutation((args: AddWordsArgs) => game.words.addWordsToGame(args));
}

/**
 * Randomize array in-place using Durstenfeld shuffle algorithm
 * Credit: https://stackoverflow.com/a/12646864
 * */
function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}
