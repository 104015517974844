import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import React, { Fragment } from 'react';
import Routes from './Routes';
import theme from './styles/theme';

export default function App() {
    return (
        <Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Routes />
            </ThemeProvider>
        </Fragment>
    );
}
