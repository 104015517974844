import generateId from '../../utils/generateId';
import Game, { UpdateRoundData } from '../Game';
import endRound from './endRound';

// Used when cluegiver has finished all available words for this round
export default async function outOfWords(game: Game) {
    // Store the timestamp this was called at so the user doesn't lose time due to latency
    const timeOfCall = Date.now();

    // Set a new runId so that any pending cloud function endRounds don't try to overwrite values
    // now that they're no longer valid.
    const runId = generateId(10);
    await game.setGameValue('runId', runId);

    const gameState = await game.getGame();
    if (!gameState) throw new Error(`No game found for game code '${game.gameCode}'.`);

    // If there are words left for this round, it means we don't need to pause for this user and
    // that we need to call endRound so that it switches to the other team and iterates the
    // cluegiver. This situation could arise if a user skips words in the round but then gets
    // the last word correct.
    const wordsLeftArr = await game.words.getWordObjsForRound(gameState.roundNumber);
    const hasWordsLeftInRound = wordsLeftArr.length > 0;

    if (hasWordsLeftInRound) {
        return endRound(game, runId);
    }

    // If reaching this point, it means there are no words left for the round
    const nextRoundNumber = gameState.roundNumber + 1;

    // If the user got all the words correct before their turn was up, we want to pause the time
    // so they can continue their turn next round.
    if (!gameState.roundStartTimestamp) {
        throw new Error('No words left for round but no roundStartTimestamp in game state.');
    }
    const roundPausedTimeRemaining = Math.min(
        Math.floor(
            gameState.secondsPerPlayer - (timeOfCall - gameState.roundStartTimestamp) / 1000
        ),
        gameState.secondsPerPlayer
    );

    // The game is over if the next round number is greater than what's allowed by the game
    const hasEnded = nextRoundNumber > Game.NumberRounds;

    const roundData: UpdateRoundData = {
        runId: null,
        betweenRounds: !hasEnded,
        roundActive: false,
        roundStartTimestamp: null,
        roundWillEndTimestamp: null,
        roundPausedTimeRemaining,
        roundWords: null,
        roundWordsIndex: null,
    };

    await game.updateGame({
        ...roundData,
        hasEnded,
        roundNumber: Math.min(nextRoundNumber, Game.NumberRounds),
    });
}
