import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Page404 from './pages/404';
import FAQPage from './pages/faq';
import GamePage from './pages/games/[game]';
import HomePage from './pages/index';
import JoinPage from './pages/join';
import NewGamePage from './pages/new';

const Routes = () => (
    <Router>
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/new" component={NewGamePage} />
            <Route exact path="/join" component={JoinPage} />
            <Route exact path="/faq" component={FAQPage} />
            <Route path={`/games/:gameCode`} component={GamePage} />
            <Route component={Page404} />
        </Switch>
    </Router>
);

export default Routes;
