import { useState } from 'react';
import { DataFetchingResult } from './types';

type Return<A, D> = [(args: A) => Promise<D | undefined>, DataFetchingResult<D>];
export default function useMutation<A, D>(func: (args: A) => Promise<D>): Return<A, D> {
    const [state, setState] = useState<DataFetchingResult<D>>({ loading: false });

    async function wrapper(args: A) {
        setState({ loading: true });
        let data: D | undefined;
        let error: Error | undefined;

        try {
            data = await func(args);
        } catch (e) {
            error = e;
        }

        setState({ loading: false, error, data });

        return data;
    }

    return [wrapper, state];
}
