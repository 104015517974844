import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useGameContext } from '../db/Game';
import { useAddNewUser } from '../db/Users';

interface Props {
    onClose: (userId: string) => void;
}

export default function EnterNameDialog({ onClose }: Props) {
    const game = useGameContext();
    const [name, setName] = useState('');
    const [addNewUser, { loading, error }] = useAddNewUser(game);

    async function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        event.stopPropagation();
        const userRef = await addNewUser({ name });
        const userId = userRef!.key!;
        game.setUserIdInLocalStorage(userId);
        onClose(userId);
    }

    return (
        <Dialog open={true} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>Enter Display Name</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    {loading && <LinearProgress color="primary" />}
                    {error && <DialogContentText>Error: {error.message}</DialogContentText>}
                    {!loading && (
                        <TextField
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            autoFocus
                            margin="dense"
                            label="Display Name"
                            fullWidth
                            required
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary">
                        Save Name
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
