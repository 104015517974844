import { useEffect, useState } from 'react';

export function useDbPathState<T>(ref: firebase.database.Reference) {
    const [val, setVal] = useState<T | null>(null);

    useEffect(() => {
        function handleSnapshot(snapshot: firebase.database.DataSnapshot) {
            const val = snapshot.val();
            setVal(val);
        }

        ref.on('value', handleSnapshot);

        return () => {
            ref.off('value', handleSnapshot);
        };
    }, [ref]);

    return val;
}

export async function getDbVal<T>(
    ref: firebase.database.Reference | firebase.database.Query
): Promise<T | null> {
    try {
        const snapshot = await ref.once('value');
        return snapshot.val() as T;
    } catch {
        return null;
    }
}
