import * as React from 'react';
import firebaseApp from '../firebaseApp';
import GameAPI from '../services/GameAPI';
import { getDbVal, useDbPathState } from './index';
import Teams, { TeamName } from './Teams';
import useMutation from './useMutation';
import Users from './Users';
import Words, { WordObjWithKey } from './Words';

interface RoundData {
    runId?: string;
    betweenRounds?: boolean; // Indicates if users can see the words as a refresher
    roundActive?: boolean;
    roundStartTimestamp?: number;
    roundWillEndTimestamp?: number;
    roundPausedTimeRemaining?: number;
    roundWords?: WordObjWithKey[];
    roundWordsIndex?: number;
}

// Mkae RoundData where nothing can be undefined but can be null so it can clear data in
// firebase.update().
export type UpdateRoundData = {
    [P in keyof RoundData]-?: RoundData[P] | null;
};

export interface IGame extends RoundData {
    creatorId: string;
    wordsPerPlayer: number;
    secondsPerPlayer: number;
    showWordRefresher: boolean;
    hasStarted: boolean;
    hasEnded: boolean;
    createdTimestamp: number; // Date.now()
    activeTeam?: TeamName;
    roundNumber: number;
}

interface LocalStorage {
    userId: string;
    gameCode: string;
}

// When using Firebase .update() passing null for a value removes it
type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export const GameContext = React.createContext<Game | null>(null);

export default class Game {
    static NumberRounds = 3;
    static ParentPath = 'games';
    static StorageKey = '_salad_bowl_online';
    // This ref is /games/[gameCode]
    public db: firebase.database.Database;
    public ref: firebase.database.Reference;
    public api: GameAPI;
    public teams: Teams;
    public users: Users;
    public words: Words;

    constructor(public gameCode: string, adminDb?: firebase.database.Database) {
        this.db = adminDb ?? firebaseApp.database();
        this.ref = this.db.ref(Game.ParentPath).child(gameCode);
        this.api = new GameAPI(this);
        this.teams = new Teams(this);
        this.users = new Users(this);
        this.words = new Words(this);
    }

    getGame() {
        return getDbVal<IGame>(this.ref);
    }

    getGameValue<K extends keyof IGame>(key: K) {
        return getDbVal<IGame[K]>(this.ref.child(key));
    }

    setGameValue<K extends keyof IGame>(key: K, value: IGame[K]) {
        return this.ref.child(key).set(value);
    }

    removeGameValue<K extends keyof IGame>(key: K) {
        return this.ref.child(key).remove();
    }

    /** Check if this game code exists in database already */
    exists() {
        return this.ref.once('value').then((snapshot) => snapshot.exists());
    }

    updateGame(update: Partial<Nullable<IGame>>) {
        return this.ref.update(update);
    }

    setFullGame(game: IGame) {
        return this.ref.set(game);
    }

    getUserIdFromLocalStorage(): string | null {
        const item = window.localStorage.getItem(Game.StorageKey);
        if (!item) return null;

        // Ensure that the saved User ID matches to this game
        const localStorage = JSON.parse(item) as LocalStorage;
        if (localStorage.gameCode === this.gameCode) {
            return localStorage.userId;
        } else {
            return null;
        }
    }

    setUserIdInLocalStorage(userId: string) {
        const storage: LocalStorage = { userId, gameCode: this.gameCode };
        window.localStorage.setItem(Game.StorageKey, JSON.stringify(storage));
    }

    async startGame() {
        // Make sure that neither team has 0 players
        const { teamOne, teamTwo } = await this.users.getUserCountPerTeam();
        if (teamOne === 0 || teamTwo === 0) {
            throw new Error('Cannot start game with team with 0 players.');
        }

        // Randomly decide which team is up
        const startingTeam = this.teams.getRandomTeam();

        // Set that as its turn
        await this.setGameValue('activeTeam', startingTeam);

        // Find a clue giver on that team
        await this.teams.iterateTeamClueGiver(startingTeam);

        // Start the game
        await this.setGameValue('hasStarted', true);
    }
}

export function useGameContext() {
    const gameContext = React.useContext(GameContext);

    if (!gameContext) {
        throw new Error('Game not available in useGameContext call.');
    }

    return gameContext;
}

export function useGame(gameCode: string) {
    const ref = React.useMemo(() => {
        return new Game(gameCode).ref;
    }, [gameCode]);
    return useDbPathState<IGame>(ref);
}

export function useGameValue<K extends keyof IGame>(game: Game, key: K) {
    const ref = React.useMemo(() => {
        return game.ref.child(key);
    }, [game.ref, key]);
    return useDbPathState<IGame[K]>(ref);
}

export function useStartGame(game: Game) {
    return useMutation(() => game.startGame());
}
