import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const Page: React.FC = ({ children }) => {
    const classes = useStyles(useTheme());

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" className={classes.title}>
                        <Link to="/" className={classes.link}>
                            <img
                                alt="Icon of bowl of salad"
                                src="/salad.png"
                                className={classes.img}
                            />
                            Salad Bowl Game
                        </Link>
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.body}>{children}</div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
        '& a': {
            color: 'white',
            textDecoration: 'none',
        },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
    },
    img: {
        height: theme.typography.h4.fontSize,
        margin: theme.spacing(0, 2),
    },
    body: {
        padding: theme.spacing(4),
    },
}));

export default Page;
