import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    LinearProgress,
    Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useGameContext } from '../db/Game';

// In between rounds let users look at words
export default function WordsRefresher() {
    const [loading, setLoading] = useState(false);
    const [words, setWords] = useState<string[]>([]);
    const game = useGameContext();

    useEffect(() => {
        async function getWords() {
            setLoading(true);
            const words = await game.words.getWords();
            words.sort();
            setWords(words);
            setLoading(false);
        }

        if (words.length === 0) {
            getWords();
        }
    }, [game, words]);

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>We're in between rounds, review the available words here.</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {loading ? (
                    <LinearProgress color="primary" />
                ) : (
                    <ul>
                        {words.map((word, index) => (
                            <li key={index}>{word}</li>
                        ))}
                    </ul>
                )}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}
