import { useCallback, useEffect, useRef, useState } from 'react';
import Game, { useGameValue } from '../db/Game';

export default function useTimeLeft(game: Game): number | null {
    const roundActive = useGameValue(game, 'roundActive');
    const roundStart = useGameValue(game, 'roundStartTimestamp');
    const roundWillEnd = useGameValue(game, 'roundWillEndTimestamp');
    const roundPausedTimeRemaining = useGameValue(game, 'roundPausedTimeRemaining');

    const interval = useRef<number | null>(null);
    const [timeLeft, setTimeLeft] = useState<number | null>(null);

    const clearTimerInterval = useCallback(() => {
        if (interval.current) {
            window.clearInterval(interval.current);
            interval.current = null;
        }
    }, []);

    // Once we get a roundStart value, start showing the timer
    useEffect(() => {
        if (!roundStart || !roundWillEnd) return;

        clearTimerInterval();

        // Figure out how much time is left. Date.now uses milliseconds
        const timeLeftS = Math.floor((roundWillEnd - Date.now()) / 1000);
        setTimeLeft(timeLeftS);

        // Set an interval to update it every second and if it passes the roundWillEnd
        // then can stop the interval
        interval.current = window.setInterval(() => {
            if (Date.now() >= roundWillEnd) {
                setTimeLeft(0);
                clearTimerInterval();
            } else {
                setTimeLeft((timeLeft) => Math.max(0, (timeLeft ?? 0) - 1));
            }
        }, 1000);
    }, [roundStart, roundWillEnd, clearTimerInterval]);

    // Once we get a roundEnd or roundPause value, clear out the callbacks.
    useEffect(() => {
        if (roundActive === false || roundPausedTimeRemaining) clearTimerInterval();
    }, [roundActive, roundPausedTimeRemaining, clearTimerInterval]);

    return timeLeft;
}
