import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
} from '@material-ui/core';
import { CheckCircleOutline, ExpandMore, HourglassEmpty } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import React from 'react';
import { useGameContext } from '../db/Game';
import { TeamName } from '../db/Teams';
import { IDUser, useUsers } from '../db/Users';

interface Props {
    user: IDUser;
}

export default function ShowTeams({ user }: Props) {
    const game = useGameContext();
    const classes = useStyles(useTheme());
    const users = useUsers(game);

    const teamOneUsers = users?.filter((user) => user.team === TeamName.One) ?? [];
    const teamTwoUsers = users?.filter((user) => user.team === TeamName.Two) ?? [];

    function teamDisplay(team: TeamName) {
        return team === TeamName.One ? 'Team 1' : 'Team 2';
    }

    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Typography>You're on {teamDisplay(user.team)}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => game.users.changeUserTeam(user.id, user.team)}
                    >
                        Change Team
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.panelContainer}>
                <TeamPanel name="Team One" users={teamOneUsers} />
                <TeamPanel name="Team Two" users={teamTwoUsers} />
            </div>
        </div>
    );
}

interface SingleTeamProps {
    name: string;
    users: IDUser[];
}
function TeamPanel({ name, users }: SingleTeamProps) {
    const classes = useStyles(useTheme());
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>{name}</Typography>
                <Typography className={classes.secondaryHeading}>
                    {users.filter((user) => user.hasEnteredWords).length} of {users.length} players
                    ready
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <List>
                    {users.map((user) => (
                        <ListItem key={user.id}>
                            <ListItemIcon>
                                {user.hasEnteredWords ? <CheckCircleOutline /> : <HourglassEmpty />}
                            </ListItemIcon>
                            <ListItemText primary={user.name} />
                        </ListItem>
                    ))}
                </List>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    panelContainer: {
        margin: theme.spacing(2, 0),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
