import { useMemo } from 'react';
import Game from './Game';
import { getDbVal, useDbPathState } from './index';

export enum TeamName {
    One = 'teamOne',
    Two = 'teamTwo',
}

interface ITeam {
    score: number;
    clueGiverId?: string;
}

interface TeamWithName extends ITeam {
    name: TeamName;
}

export default class Teams {
    static ParentPath = 'teams';
    // /teams/[gameCode]
    public ref: firebase.database.Reference;

    constructor(private game: Game) {
        this.ref = this.game.db.ref(Teams.ParentPath).child(game.gameCode);
    }

    getTeamValue<K extends keyof ITeam>(teamName: TeamName, key: K) {
        return getDbVal<ITeam[K]>(this.ref.child(teamName).child(key));
    }

    setTeamValue<K extends keyof ITeam>(teamName: TeamName, key: K, value: ITeam[K]) {
        return this.ref.child(teamName).child(key).set(value);
    }

    setFullTeams() {
        const team: ITeam = {
            score: 0,
        };
        return this.ref.set({
            [TeamName.One]: team,
            [TeamName.Two]: team,
        });
    }

    async iterateTeamClueGiver(teamName: TeamName) {
        const currentClueGiver = await this.getTeamValue(teamName, 'clueGiverId');
        const nextClueGiver = await this.game.users.getNextClueGiverForTeam(
            teamName,
            currentClueGiver
        );
        await this.setTeamValue(teamName, 'clueGiverId', nextClueGiver.id);
    }

    getRandomTeam() {
        // Get a random number that's 0 or 1
        const index = Math.floor(Math.random() * 2);
        const teams = [TeamName.One, TeamName.Two];
        return teams[index];
    }

    getOtherTeam(teamName: TeamName) {
        if (teamName === TeamName.One) {
            return TeamName.Two;
        } else {
            return TeamName.One;
        }
    }
}

export function useTeam(game: Game, teamName: TeamName | null | undefined) {
    const teamsRef = useMemo(() => {
        const child = teamName || 'null';
        return game.teams.ref.child(child);
    }, [game.teams.ref, teamName]);
    const team = useDbPathState<ITeam>(teamsRef);
    if (team && teamName) {
        const teamWithName: TeamWithName = { ...team, name: teamName };
        return teamWithName;
    } else {
        return null;
    }
}
