import { Button, Container, LinearProgress, Theme, Typography } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/styles';
import React, { Fragment, useState } from 'react';
import { useGameContext, useGameValue, useStartGame } from '../db/Game';
import { useUser } from '../db/Users';
import copyToClipboard from '../utils/copyToClipboard';
import AddWords from './AddWords';
import EnterNameDialog from './EnterNameDialog';
import ShowTeams from './ShowTeams';

const DEFAULT_COPY_TEXT = 'Copy Game Code';

export default function NotStartedGame() {
    const game = useGameContext();
    const classes = useStyles(useTheme());
    const [userId, setUserId] = useState(() => game.getUserIdFromLocalStorage());
    const [showEnterNameDialog, setShowEnterNameDialog] = useState(userId === null);
    const user = useUser(game, userId);
    const wordsPerPlayer = useGameValue(game, 'wordsPerPlayer');
    const hasStarted = useGameValue(game, 'hasStarted');
    const creatorId = useGameValue(game, 'creatorId');
    const [startGame, { loading, error }] = useStartGame(game);

    const [copyText, setCopyText] = useState(DEFAULT_COPY_TEXT);

    function handleDialogClose(userId: string) {
        setUserId(userId);
        setShowEnterNameDialog(false);
    }

    function handleCopyToClipboardClick() {
        copyToClipboard(game.gameCode);
        setCopyText('Copied!');
        window.setTimeout(() => {
            setCopyText(DEFAULT_COPY_TEXT);
        }, 2000);
    }

    function determineComponent() {
        if (hasStarted) {
            // <Container/> element can't have null as a child
            return <div />;
        } else if (error) {
            return <Typography color="error">Error: {error.message}</Typography>;
        } else if (showEnterNameDialog) {
            return <EnterNameDialog onClose={handleDialogClose} />;
        } else if (loading || !user) {
            return <LinearProgress color="primary" />;
        } else if (!user.hasEnteredWords && wordsPerPlayer !== null) {
            return <AddWords numWords={wordsPerPlayer} userId={user.id} />;
        } else {
            return (
                <Fragment>
                    <ShowTeams user={user} />
                    {user.id === creatorId ? (
                        <Button
                            className={classes.startGameButton}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={startGame}
                        >
                            Start Game
                        </Button>
                    ) : (
                        <Typography variant="body1">
                            Waiting for game creator to start game...
                        </Typography>
                    )}
                    <Typography className={classes.invite}>
                        To invite your friends to join, have them use game code{' '}
                        <code>{game.gameCode}</code> or send them a link to this page.
                    </Typography>
                    <Button size="small" variant="outlined" onClick={handleCopyToClipboardClick}>
                        {copyText}
                    </Button>
                </Fragment>
            );
        }
    }

    return <Container maxWidth="md">{determineComponent()}</Container>;
}

const useStyles = makeStyles((theme: Theme) => ({
    startGameButton: {
        marginTop: theme.spacing(2),
    },
    invite: {
        margin: theme.spacing(3, 0, 1, 0),
        '& code': {
            backgroundColor: pink[100],
            color: pink[800],
            borderRadius: '0.25rem',
            padding: '0.2rem 0.4rem',
        },
    },
}));
