import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    TextField,
    Typography,
} from '@material-ui/core';
import { pink } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { useGameContext } from '../db/Game';
import { useAddWordsToGame } from '../db/Words';

interface Props {
    numWords: number;
    userId: string;
}

export default function AddWords({ numWords, userId }: Props) {
    const game = useGameContext();
    const classes = useStyles(useTheme());
    const [addWordsToGame, { loading, error }] = useAddWordsToGame(game);
    const [words, setWords] = useState(() => Array(numWords).fill(''));
    const [dialogOpen, setDialogOpen] = useState(false);
    const wordCount = words.filter((word) => word !== '').length;

    function setAtIndex(index: number, val: string) {
        const newWords = [...words];
        newWords[index] = val;
        setWords(newWords);
    }

    function handleDialogClose() {
        setDialogOpen(false);
    }

    return (
        <div>
            <Typography variant="h5">Add Your Submissions</Typography>
            <Typography variant="body2" className={classes.invite}>
                To invite your friends to join, have them use game code <code>{game.gameCode}</code>{' '}
                or send them a link to this page.
            </Typography>
            <div className={classes.explanation} onClick={() => setDialogOpen(true)}>
                <InfoIcon fontSize="small" />
                What type of clues should I add?
            </div>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>What types of clues to add</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The clues you add below can be famous people (e.g. Pope Francis, John F.
                        Kennedy), fictional characters (Harry Potter, Katniss Everdeen), musical
                        groups (The Rolling Stones), or other cultural items (Little House On The
                        Prairie, Tiger King, etc.).
                    </DialogContentText>
                    <DialogContentText>
                        Think the types of clues you might get in CatchPhrase. The idea is that you
                        choose a famous person or thing that at least half the people in the room
                        have probably heard of.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {loading && <LinearProgress color="primary" />}
            {error && (
                <Typography color="error">Error submitting items: {error.message}</Typography>
            )}
            {!loading && (
                <Fragment>
                    {words.map((word, index) => (
                        <TextField
                            className={classes.textField}
                            key={index}
                            label={`Clue ${index + 1}`}
                            value={word}
                            onChange={(event) => setAtIndex(index, event.target.value)}
                            fullWidth
                            autoComplete="off"
                            spellCheck="false"
                        />
                    ))}
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        onClick={() => addWordsToGame({ words, userId })}
                        disabled={wordCount === 0}
                    >
                        Submit Words
                    </Button>
                </Fragment>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
    },
    invite: {
        '& code': {
            backgroundColor: pink[100],
            color: pink[800],
            borderRadius: '0.25rem',
            padding: '0.1rem 0.3rem',
        },
    },
    explanation: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(14),
        margin: theme.spacing(1, 0),
        cursor: 'pointer',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
}));
