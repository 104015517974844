import {
    Button,
    colors,
    Container,
    Grid,
    LinearProgress,
    Theme,
    Typography,
} from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useGame, useGameContext } from '../db/Game';
import { TeamName, useTeam } from '../db/Teams';
import { useUser } from '../db/Users';
import ClueGiver from './ClueGiver';
import GameOverDisplay from './GameOverDisplay';
import Timer from './Timer';
import WordsRefresher from './WordsRefresher';

export default function StartedGame() {
    const game = useGameContext();
    const gameCode = game.gameCode;
    const classes = useStyles(useTheme());
    const [loading, setLoading] = useState(false);

    const userId = game.getUserIdFromLocalStorage();
    const user = useUser(game, userId);
    const gameState = useGame(gameCode);
    const teamOne = useTeam(game, TeamName.One);
    const teamTwo = useTeam(game, TeamName.Two);
    const activeTeam = getActiveTeam();
    const teamOneActive = gameState?.activeTeam === TeamName.One;
    const teamTwoActive = gameState?.activeTeam === TeamName.Two;

    const clueGiver = useUser(game, activeTeam?.clueGiverId);
    const userIsCluegiver = user && clueGiver && user.id === clueGiver.id;

    const userTeam = user && user.team === TeamName.One ? teamOne : teamTwo;
    const otherTeam = user && user.team === TeamName.One ? teamTwo : teamOne;
    const userTeamIsActive = userTeam && activeTeam && userTeam.name === activeTeam.name;

    const roundStartTimestamp = gameState?.roundStartTimestamp;
    useEffect(() => {
        if (roundStartTimestamp && loading) {
            setLoading(false);
        }
    }, [roundStartTimestamp, loading]);

    function getActiveTeam() {
        const activeTeam = gameState?.activeTeam;
        if (activeTeam === null) return null;
        if (activeTeam === TeamName.One) return teamOne;
        return teamTwo;
    }

    function getRoundText() {
        if (gameState?.hasEnded) {
            return 'Game Over';
        }
        switch (gameState?.roundNumber) {
            case 1:
                return `Round 1: Verbal Clues`;
            case 2:
                return `Round 2: One Word + Charades`;
            case 3:
                return `Round 3: Charades`;
        }
    }

    async function startRound() {
        try {
            setLoading(true);
            await game.api.startRound();
        } catch (error) {
            // TODO: Don't use an alert here
            alert(error.message);
        } finally {
            setLoading(false);
        }
    }

    function isOnThisTeam(team: TeamName) {
        if (user?.team === team) {
            return (
                <div className={`${classes.userTeam} ${classes.secondaryColor}`}>
                    You're on this team
                </div>
            );
        } else {
            return null;
        }
    }

    function renderGameState() {
        if (gameState?.hasEnded) {
            return (
                <GameOverDisplay
                    userScore={userTeam?.score ?? 0}
                    otherScore={otherTeam?.score ?? 0}
                />
            );
        } else if (userIsCluegiver) {
            if (gameState?.roundStartTimestamp) {
                return <ClueGiver />;
            } else {
                return (
                    <Fragment>
                        <Typography
                            variant="body1"
                            gutterBottom
                            align="center"
                            className={`${classes.marginTop} ${classes.secondaryColor}`}
                        >
                            You're up as cluegiver! Whenever you're ready click the Start Round
                            button.
                        </Typography>
                        <div className={classes.marginTop}>
                            <Button
                                onClick={startRound}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Start Round!
                            </Button>
                        </div>
                    </Fragment>
                );
            }
        } else {
            return (
                <Typography
                    variant="body1"
                    className={`${classes.marginTop} ${classes.secondaryColor}`}
                    align="center"
                >
                    {userTeamIsActive
                        ? 'Your team is up this round, be prepared to guess!'
                        : 'The other team is up this round.'}{' '}
                    {clueGiver?.name ?? 'The cluegiver'}{' '}
                    {gameState?.roundStartTimestamp ? 'is' : 'will be'} giving clues.
                </Typography>
            );
        }
    }

    if (loading || !gameState || !user || !userTeam || !otherTeam) {
        return (
            <Container maxWidth="md">
                <LinearProgress color="primary" />
            </Container>
        );
    }
    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <div className={`${classes.roundText} ${classes.secondaryColor}`}>
                    {getRoundText()}
                </div>
                <div className={classes.scoreGrid}>
                    <div
                        style={{ gridArea: 'teamOne' }}
                        className={`${classes.teamName} ${classes.teamOneColor}`}
                    >
                        Team One
                    </div>
                    <div style={{ gridArea: 'teamOneUser' }}>{isOnThisTeam(TeamName.One)}</div>
                    <div
                        style={{ gridArea: 'score' }}
                        className={`${classes.score} ${classes.secondaryColor}`}
                    >
                        <span className={classes.teamOneColor}>{teamOne?.score}</span>
                        &nbsp;–&nbsp;
                        <span className={classes.teamTwoColor}>{teamTwo?.score}</span>
                    </div>
                    <div
                        style={{ gridArea: 'teamTwo' }}
                        className={`${classes.teamName} ${classes.teamTwoColor}`}
                    >
                        Team Two
                    </div>
                    <div style={{ gridArea: 'teamTwoUser' }}>{isOnThisTeam(TeamName.Two)}</div>
                    <div
                        style={{
                            gridArea: 'activeTeam',
                        }}
                        className={`${classes.activeTeam} ${classes.secondaryColor}`}
                    >
                        {teamOneActive && (
                            <ArrowLeft
                                style={{ marginLeft: '-0.5rem' }}
                                className={classes.teamOneColor}
                            />
                        )}
                        Active Team
                        {teamTwoActive && (
                            <ArrowRight
                                style={{ marginRight: '-0.5rem' }}
                                className={classes.teamTwoColor}
                            />
                        )}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.25rem' }}>
                    <Timer />
                </div>
                {!gameState.hasEnded && gameState.betweenRounds && gameState.showWordRefresher && (
                    <Grid item container direction="column" xs={12} alignItems="center">
                        <WordsRefresher />
                    </Grid>
                )}
                <Grid item container direction="column" xs={12} alignItems="center">
                    {renderGameState()}
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    secondaryColor: {
        color: theme.palette.grey[700],
    },
    scoreGrid: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto auto auto',
        gridTemplateAreas: `
            'teamOne        score       teamTwo'
            'teamOneUser    score       teamTwoUser'
            '.              activeTeam  .'
        `,
    },
    roundText: {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(20),
        },
    },
    score: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(22),
        marginTop: '2px',
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(40),
            marginTop: 0,
        },
        fontWeight: theme.typography.fontWeightBold,
    },
    teamOneColor: {
        color: colors.blue[500],
    },
    teamTwoColor: {
        color: colors.red[500],
    },
    teamName: {
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(18),
        alignSelf: 'end',
        marginTop: '0.25rem',
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(24),
        },
    },
    userTeam: {
        textAlign: 'center',
        marginTop: '-0.125rem',
        fontSize: theme.typography.pxToRem(8),
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    activeTeam: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(10),
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(14),
        },
    },
}));
