import { Card, Theme, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import React, { useMemo } from 'react';

interface Props {
    userScore: number;
    otherScore: number;
}

const TIE_GIFS = ['https://media.giphy.com/media/xTiN0i1KAwytBqMEDK/200w_d.gif'];
const WIN_GIFS = [
    'https://media.giphy.com/media/YTbZzCkRQCEJa/giphy.gif',
    'https://media.giphy.com/media/lMameLIF8voLu8HxWV/giphy.gif',
    'https://media.giphy.com/media/1PMVNNKVIL8Ig/giphy.gif',
    'https://media.giphy.com/media/yoJC2COHSxjIqadyZW/giphy.gif',
    'https://media.giphy.com/media/5PSPV1ucLX31u/giphy.gif',
    'https://media.giphy.com/media/1dMNqVx9Kb12EBjFrc/giphy.gif',
    'https://media.giphy.com/media/LnpQIOZ1Ta1x7iF7u5/giphy-downsized.gif',
];
const LOSE_GIFS = [
    'https://media.giphy.com/media/JWCahAf61Z43e/giphy.gif',
    'https://media.giphy.com/media/EU5ox7Mve7FS0/giphy.gif',
    'https://media.giphy.com/media/ej2dS4FUCNRJK/giphy-downsized.gif',
    'https://media.giphy.com/media/a9xhxAxaqOfQs/giphy.gif',
    'https://media.giphy.com/media/3oEjI80DSa1grNPTDq/giphy.gif',
    'https://media.giphy.com/media/8boMf1VXVHoJy/giphy.gif',
    'https://media.giphy.com/media/W1ZAeDHkUA539d6LtM/giphy.gif',
];

export default function GameOverDisplay({ userScore, otherScore }: Props) {
    const classes = useStyles(useTheme());
    const { text, gif } = useMemo(() => getGifAndText({ userScore, otherScore }), [
        userScore,
        otherScore,
    ]);

    return (
        <Card className={classes.card}>
            <Typography variant="h4" className={classes.marginTop} align="center">
                Game Over
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
                {text}
            </Typography>
            <img
                alt="Gif representing game state"
                src={gif}
                style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '400px',
                    width: 'auto',
                    height: 'auto',
                }}
            />
        </Card>
    );
}

function getGifAndText({ userScore, otherScore }: Props): { text: string; gif: string } {
    function getGif(arr: string[]) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    if (userScore === otherScore) {
        return { text: 'Looks like a tie. Play again as a rubber match?', gif: getGif(TIE_GIFS) };
    } else if (userScore > otherScore) {
        return { text: 'Congrats your team won!', gif: getGif(WIN_GIFS) };
    } else {
        return { text: 'Tough match, better luck next time.', gif: getGif(LOSE_GIFS) };
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    marginTop: {
        marginTop: theme.spacing(2),
    },
    card: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 2, 2, 2),
        maxWidth: '400px',
    },
}));
