import Game from '../db/Game';
import BaseAPI from './BaseAPI';

const HANDLE_ROUND_ENDPOINT =
    'https://us-central1-salad-bowl-ed2bd.cloudfunctions.net/handleRound-handleRound';

export default class GameAPI extends BaseAPI {
    constructor(private game: Game) {
        super();
    }

    async startRound() {
        const resp = await this.post(HANDLE_ROUND_ENDPOINT, { gameCode: this.game.gameCode });
        if (!resp.ok) {
            const text = await resp.text();
            throw new Error(text);
        }
    }
}
