export default class BaseAPI {
    async fetch(url: string, options: RequestInit = {}) {
        const defaultHeaders: HeadersInit = {
            'Content-Type': 'application/json',
        };

        options.headers = { ...defaultHeaders, ...options.headers };

        const resp = await window.fetch(url, options);

        return resp;
    }

    async get(url: string, signal?: AbortSignal) {
        return this.fetch(url, { signal });
    }

    async post(url: string, body?: string | object, signal?: AbortSignal) {
        if (typeof body !== 'string') body = JSON.stringify(body);
        return this.fetch(url, { method: 'POST', body, signal });
    }
}
