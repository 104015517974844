export default function copyToClipboard(text: string) {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = '-9999px';
    div.innerText = text;
    document.body.appendChild(div);

    const selection = document.getSelection()!;
    selection.removeAllRanges();

    const range = document.createRange();
    range.selectNodeContents(div);
    selection.addRange(range);

    document.execCommand('copy');

    div.remove();
}
