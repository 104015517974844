import * as firebase from 'firebase/app';
import 'firebase/database';

function getFirebaseApp() {
    if (firebase.apps.length > 0) return firebase.app();
    return firebase.initializeApp({
        apiKey: 'AIzaSyDUXbDCRjbNHG26fi-fHyflPpVt_6OWCo8',
        authDomain: 'salad-bowl-ed2bd.firebaseapp.com',
        databaseURL: 'https://salad-bowl-ed2bd.firebaseio.com',
        projectId: 'salad-bowl-ed2bd',
        storageBucket: 'salad-bowl-ed2bd.appspot.com',
        messagingSenderId: '647299001775',
        appId: '1:647299001775:web:8d856fe2a31ea9ae4f935a',
    });
}

const firebaseApp = getFirebaseApp();

export default firebaseApp;
