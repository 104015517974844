import { Button, Container, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../components/Page';
import Game from '../db/Game';
import { validateGameCode } from '../utils/generateId';

export default function JoinPage() {
    const classes = useStyles(useTheme());
    const history = useHistory();

    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const isValid = validateGameCode(code);

    async function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (!isValid) return;
        const game = new Game(code);
        try {
            setLoading(true);
            setError('');
            const exists = await game.exists();
            if (exists) {
                history.push(`/games/${code}`);
            } else {
                throw new Error(`No game found for code '${code}'.`);
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }

    return (
        <Page>
            <Container className={classes.container} maxWidth="sm">
                <Typography variant="h4">Enter Game Code</Typography>
                <Typography variant="subtitle1">Capitalization doesn't matter</Typography>
                {error && <Typography color="error">Error: {error}</Typography>}
                <form onSubmit={handleSubmit}>
                    <TextField
                        className={classes.textField}
                        value={code}
                        onChange={(event) => setCode(event.target.value.toUpperCase())}
                        placeholder="Game code"
                        required={true}
                        disabled={loading}
                    />
                    <br />
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={!isValid || loading}
                    >
                        {loading ? 'Joining Game...' : 'Join Game'}
                    </Button>
                </form>
            </Container>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0),
    },
    textField: {
        margin: theme.spacing(2, 0),
    },
}));
