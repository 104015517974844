import { Typography } from '@material-ui/core';
import React from 'react';
import { useGameContext, useGameValue } from '../db/Game';
import useTimeLeft from '../utils/useTimeLeft';

export default function Timer() {
    const game = useGameContext();
    const roundActive = useGameValue(game, 'roundActive');
    const timeLeft = useTimeLeft(game);

    // Format seconds at MM:SS
    function formatTimeLeft(timeLeft: number | null): string {
        if (timeLeft === null) return '';
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft - minutes * 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    if (timeLeft === null || roundActive === false) return null;
    return <Typography variant="h3">{formatTimeLeft(timeLeft)}</Typography>;
}
