import { Container } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../components/Page';

export default function Page404() {
    return (
        <Page>
            <Container maxWidth="sm">
                No page was found for <code>{useLocation().pathname}</code>.
            </Container>
        </Page>
    );
}
