import { colors, Container, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import Page from '../components/Page';

export default function FAQPage() {
    const classes = useStyles(useTheme());

    const Header: FC = ({ children }) => (
        <Typography className={classes.header}>{children}</Typography>
    );
    const SubHeader: FC = ({ children }) => (
        <Typography className={classes.subheader}>{children}</Typography>
    );
    const Body: FC = ({ children }) => <Typography className={classes.body}>{children}</Typography>;

    return (
        <Page>
            <Container maxWidth="lg">
                <Header>Creating and joining games</Header>
                <Body>
                    Once you've created a game, you can send the game code or a direct link to the
                    game page to have your friends join.
                </Body>
                <Body>
                    From there, all game actions will be synced in real time across all devices.
                </Body>

                <Header>How to play</Header>
                <Body>
                    This game goes by many names: "salad bowl", "fish bowl", or "celebrity". The
                    rules below are based on the game{' '}
                    <a
                        href="https://boardgamegeek.com/boardgame/1353/times"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                    >
                        Time's Up
                    </a>{' '}
                    because that's what my family plays with. So if the below rules are different
                    than how you normally play, just do whatever you normally do.
                </Body>
                <Body>
                    The game is played by having all players submit words to be guessed. These words
                    should be similar to answers in Taboo or CatchPhrase such as famous people,
                    books, movies, etc. Ideally they would be words that everyone would know.
                </Body>
                <Body>
                    After all words have been sumitted, the game is broken up into 3 rounds:
                </Body>
                <SubHeader>Round 1: Verbal Clues</SubHeader>
                <Body>
                    Try to get teammates to guess the word without saying the actual word. Normal
                    CatchPhrase rules apply meaning no "sounds like", "rhymes with", "starts with",
                    etc. In this round you can normally skip difficult words but note you cannot
                    come back to them in the same round.
                </Body>
                <SubHeader>In Between Rounds</SubHeader>
                <Body>
                    In between each round, users will have the opportunity to see all available
                    words so they can get reacquainted with them before the more difficult Rounds 2
                    and 3.
                </Body>
                <SubHeader>Round 2: One Word Clue + Charades</SubHeader>
                <Body>
                    In Round 2, you give a single word clue and then act out the word with charades.
                    Normal charades rules apply so no pointing, no sounds, etc. If the cluegiver
                    accidentally gives more than a one word clue, they must skip that word. Some
                    people play by different rules, but normally Rounds 2 and 3 there is no skipping
                    allowed. If you get a hard word, you must work through it.
                </Body>
                <SubHeader>Round 3: Charades</SubHeader>
                <Body>
                    In the final round, you can only use charades to guess. This round is also
                    normally played without skips.
                </Body>
            </Container>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: theme.spacing(3, 0, 0, 0),
        '&:first-of-type': {
            marginTop: 0,
        },
    },
    subheader: {
        fontSize: '1.15rem',
        fontWeight: 'bold',
        color: theme.palette.grey[800],
        marginTop: theme.spacing(2),
    },
    body: {
        fontSize: '1rem',
        lineHeight: 1.5,
        margin: theme.spacing(1, 0),
    },
    link: {
        color: colors.blue[600],
    },
}));
