import {
    Button,
    Container,
    FormControlLabel,
    Grid,
    LinearProgress,
    Switch,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../components/Page';
import { useCreateNewGame } from '../db/flows/createNewGame';
import Game from '../db/Game';

export default function NewGamePage() {
    const [createNewGame, { loading, data, error }] = useCreateNewGame();
    const history = useHistory();
    const classes = useStyles(useTheme());

    const [creatorName, setCreateName] = useState('');
    const [wordsPerPlayer, setWordsPerPlayer] = useState(5);
    const [secondsPerPlayer, setSecondsPerPlayer] = useState(60);
    const [showWordRefresher, setShowWordRefresher] = useState(true);

    useEffect(() => {
        if (data?.gameCode) {
            history.push(`/${Game.ParentPath}/${data.gameCode}`);
        }
    }, [data, history]);

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        event.stopPropagation();
        createNewGame({ creatorName, wordsPerPlayer, secondsPerPlayer, showWordRefresher });
    }

    return (
        <Page>
            <Container maxWidth="md">
                <Typography variant="h4" gutterBottom>
                    Create New Game
                </Typography>
                {error && <p>Error: {error.message}</p>}
                {loading && <LinearProgress color="primary" />}
                {!loading && (
                    <form onSubmit={handleSubmit}>
                        <Grid container className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Your Display Name"
                                    fullWidth
                                    value={creatorName}
                                    onChange={(event) => setCreateName(event.target.value)}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="number"
                                    label="Words Per Player"
                                    inputProps={{ min: 1, max: 20 }}
                                    fullWidth
                                    value={wordsPerPlayer}
                                    onChange={(event) =>
                                        setWordsPerPlayer(parseInt(event.currentTarget.value))
                                    }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="number"
                                    label="Seconds Per Player"
                                    inputProps={{ min: 10, max: 180 }}
                                    fullWidth
                                    value={secondsPerPlayer}
                                    onChange={(event) =>
                                        setSecondsPerPlayer(parseInt(event.currentTarget.value))
                                    }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showWordRefresher}
                                            onChange={(event) =>
                                                setShowWordRefresher(event.target.checked)
                                            }
                                        />
                                    }
                                    label="Allow reviewing words between rounds"
                                />
                            </Grid>
                        </Grid>
                        <Button variant="contained" size="large" color="primary" type="submit">
                            Create Game
                        </Button>
                    </form>
                )}
            </Container>
        </Page>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        padding: theme.spacing(2, 0),
    },
}));
