import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Theme,
    Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/styles';
import React from 'react';
import outOfWords from '../db/flows/outOfWords';
import { useGameContext, useGameValue } from '../db/Game';
import { useTeam } from '../db/Teams';
import useTimeLeft from '../utils/useTimeLeft';

export default function ClueGiver() {
    const classes = useStyles(useTheme());
    const game = useGameContext();
    const timeLeft = useTimeLeft(game);
    const buttonsDisabled = timeLeft === null || timeLeft <= 0;
    const { word, index, totalWords, markCorrect, markSkipped } = useGameWord();

    return (
        <Container className={classes.container}>
            {word ? (
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} sm={9} md={6}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid container direction="column">
                                    <Grid container direction="row" justify="flex-end">
                                        <Grid item>
                                            <span>
                                                {index + 1} of {totalWords}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            className={classes.cardText}
                                        >
                                            {word}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Grid container justify="space-between">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={markSkipped}
                                        disabled={buttonsDisabled || index === totalWords - 1}
                                    >
                                        Skip
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.correctButton}
                                        onClick={markCorrect}
                                        disabled={buttonsDisabled}
                                    >
                                        Correct
                                    </Button>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                <Typography variant="body1">Out of words for this round</Typography>
            )}
        </Container>
    );
}

interface UseGameWordReturn {
    word: string | null;
    index: number;
    totalWords: number;
    markCorrect: () => void;
    markSkipped: () => void;
}
function useGameWord(): UseGameWordReturn {
    const game = useGameContext();
    const wordsArr = useGameValue(game, 'roundWords');
    const round = useGameValue(game, 'roundNumber');
    const index = useGameValue(game, 'roundWordsIndex');
    const teamName = useGameValue(game, 'activeTeam');
    const team = useTeam(game, teamName);
    const wordObj = (wordsArr ?? [])[index ?? 0];
    const isReady =
        wordObj && wordsArr && team && round !== null && index !== null && index !== undefined;

    async function iterate() {
        if (!isReady) return;
        const nextIndex = index! + 1;
        if (nextIndex === wordsArr!.length) {
            // They're run out of words for this round
            await outOfWords(game);
        } else {
            // There are words left so iterate to the next
            game.setGameValue('roundWordsIndex', nextIndex);
        }
    }

    function markCorrect() {
        if (!isReady) return;
        game.words.setWordRoundValue(wordObj.key, round!);
        game.teams.setTeamValue(team!.name, 'score', team!.score + 1);
        iterate();
    }

    function markSkipped() {
        if (!isReady) return;
        iterate();
    }

    return {
        word: wordObj?.word ?? null,
        index: index ?? 0,
        totalWords: wordsArr?.length ?? 0,
        markCorrect,
        markSkipped,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: theme.spacing(4, 0),
    },
    card: {
        width: '100%',
    },
    cardText: {
        margin: theme.spacing(4),
    },
    cardActions: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    correctButton: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}));
