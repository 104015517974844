import generateId from '../../utils/generateId';
import Game, { IGame } from '../Game';
import useMutation from '../useMutation';

interface CreateNewGameArgs
    extends Pick<IGame, 'wordsPerPlayer' | 'secondsPerPlayer' | 'showWordRefresher'> {
    creatorName: string;
}
interface CreateNewGameData {
    gameCode: string;
}

async function createNewGame({
    creatorName,
    wordsPerPlayer,
    secondsPerPlayer,
    showWordRefresher,
}: CreateNewGameArgs): Promise<CreateNewGameData> {
    const { game, gameCode } = await getGameAndCode();

    // Add a new user property and store that ID
    const userRef = await game.users.addNewUser({ name: creatorName });
    const userId = userRef.key;
    if (userId === null) {
        throw new Error(`userRef.key is null`);
    }

    game.setUserIdInLocalStorage(userId);

    const gameValues: IGame = {
        creatorId: userId,
        wordsPerPlayer,
        secondsPerPlayer,
        showWordRefresher,
        hasStarted: false,
        hasEnded: false,
        createdTimestamp: Date.now(),
        roundNumber: 1,
    };

    await game.setFullGame(gameValues);
    await game.teams.setFullTeams();

    return { gameCode };
}

// Pulled into separate function so it can recursively call itself if the generated game code
// already exists.
async function getGameAndCode(): Promise<{ game: Game; gameCode: string }> {
    const gameCode = generateId();
    const game = new Game(gameCode);
    const alreadyExists = await game.exists();
    if (alreadyExists) {
        return getGameAndCode();
    } else {
        return { game, gameCode };
    }
}

export function useCreateNewGame() {
    return useMutation((args: CreateNewGameArgs) => createNewGame(args));
}
