import { useMemo } from 'react';
import Game from './Game';
import { getDbVal, useDbPathState } from './index';
import { TeamName } from './Teams';
import useMutation from './useMutation';

interface IUsers {
    [index: string]: IUser;
}

export interface IUser {
    name: string;
    hasEnteredWords: boolean;
    team: TeamName;
}

export interface IDUser extends IUser {
    id: string;
}

interface AddNewUserArgs {
    name: string;
}

export default class Users {
    static ParentPath = 'users';
    // /users/[gameCode]/[userId]: User
    public ref: firebase.database.Reference;

    constructor(private game: Game) {
        this.ref = this.game.db.ref(Users.ParentPath).child(this.game.gameCode);
    }

    getUsers() {
        return getDbVal<IUsers>(this.ref);
    }

    getUserValue<K extends keyof IUser>(userId: string, key: K) {
        return getDbVal<IUser[K]>(this.ref.child(userId).child(key));
    }

    setUserValue<K extends keyof IUser>(userId: string, key: K, value: IUser[K]) {
        return this.ref.child(userId).child(key).set(value);
    }

    setUser(user: IUser) {
        return this.ref.push(user);
    }

    async getUsersOnTeam(teamName: TeamName) {
        const orderByKey: keyof IUser = 'team';
        const usersMap = await this.ref
            .orderByChild(orderByKey)
            .equalTo(teamName)
            .once('value')
            .then((snapshot) => snapshot.val() as IUsers);
        return usersMapToArray(usersMap);
    }

    async getUserCountPerTeam() {
        const counts = {
            [TeamName.One]: 0,
            [TeamName.Two]: 0,
        };

        const usersMap = await this.getUsers();
        if (!usersMap) return counts;
        for (const id in usersMap) {
            const team = usersMap[id].team;
            counts[team] += 1;
        }
        return counts;
    }

    async addNewUser({ name }: AddNewUserArgs) {
        // Find which team has less people
        const { teamOne, teamTwo } = await this.getUserCountPerTeam();
        let team: TeamName;
        if (teamOne < teamTwo) {
            team = TeamName.One;
        } else if (teamTwo < teamOne) {
            team = TeamName.Two;
        } else {
            team = this.game.teams.getRandomTeam();
        }

        const newUser: IUser = {
            name,
            hasEnteredWords: false,
            team,
        };

        return this.setUser(newUser);
    }

    changeUserTeam(userId: string, currentTeam: TeamName) {
        const newTeam = this.game.teams.getOtherTeam(currentTeam);
        this.setUserValue(userId, 'team', newTeam);
    }

    async getNextClueGiverForTeam(teamName: TeamName, currentClueGiverId?: string | null) {
        const teamUsersArr = await this.getUsersOnTeam(teamName);
        if (!currentClueGiverId) {
            return teamUsersArr[0];
        }
        const currentClueGiverIndex = teamUsersArr.findIndex(
            (user) => user.id === currentClueGiverId
        );
        if (currentClueGiverIndex === -1) {
            throw new Error(`Could not find user on '${teamName}' with ID ${currentClueGiverId}`);
        }
        if (currentClueGiverIndex === teamUsersArr.length - 1) {
            // Last person on the team was up so loop back to the beginning
            return teamUsersArr[0];
        } else {
            // Otherwise just go to the next person
            return teamUsersArr[currentClueGiverIndex + 1];
        }
    }
}

export function useUser(game: Game, userId?: string | null) {
    const ref = useMemo(() => {
        const id = userId || 'null';
        return game.users.ref.child(id);
    }, [userId, game.users.ref]);
    const user = useDbPathState<IUser>(ref);
    if (user && userId) {
        const idUser: IDUser = { ...user, id: userId };
        return idUser;
    } else {
        return null;
    }
}

export function useUsers(game: Game) {
    // Firebase returns as a map, so convert to an array for easier use
    const usersMap = useDbPathState<IUsers>(game.users.ref);
    return usersMap && usersMapToArray(usersMap);
}

function usersMapToArray(usersMap: IUsers) {
    return Object.entries(usersMap)
        .reduce((arr, [key, user]) => {
            arr.push({
                ...user,
                id: key,
            });
            return arr;
        }, [] as IDUser[])
        .sort((a, b) => a.id.localeCompare(b.id));
}

export function useAddNewUser(game: Game) {
    return useMutation((args: AddNewUserArgs) => game.users.addNewUser(args));
}
