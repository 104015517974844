import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Info as InfoIcon } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/Page';

export default function HomePage() {
    const classes = useStyles(useTheme());

    return (
        <Page>
            <Container className={classes.container} maxWidth="sm">
                <Typography align="center" variant="h3">
                    Play Salad Bowl Online
                </Typography>
                <Typography align="center" variant="subtitle1">
                    Aka "Fish Bowl" aka "Celebrity". Play online with your friends in real time.
                </Typography>
                <Grid
                    className={classes.grid}
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            to="/new"
                            component={Link}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Start Game
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            to="/join"
                            component={Link}
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            size="large"
                        >
                            Join Game
                        </Button>
                    </Grid>
                    <Grid item>
                        <Link to="/faq" className={classes.link}>
                            <InfoIcon fontSize="small" />
                            How To Play
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6, 4),
    },
    grid: {
        paddingTop: theme.spacing(3),
    },
    button: {
        width: '11em',
    },
    link: {
        color: theme.palette.grey[700],
        fontSize: '1rem',
        textDecoration: 'none',
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
}));
