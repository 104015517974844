import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import NotStartedGame from '../../components/NotStartedGame';
import Page from '../../components/Page';
import StartedGame from '../../components/StartedGame';
import Game, { GameContext, useGameValue } from '../../db/Game';

export default function GamePage() {
    const { gameCode } = useParams<{ gameCode: string }>();
    const game = useMemo(() => {
        return new Game(gameCode);
    }, [gameCode]);
    const hasStarted = useGameValue(game, 'hasStarted');

    return (
        <GameContext.Provider value={game}>
            <Page>{hasStarted ? <StartedGame /> : <NotStartedGame />}</Page>
        </GameContext.Provider>
    );
}
